import { createSlice } from "@reduxjs/toolkit";
import sessionLangInit from "../../../../../utils/sessionLangInit";
import i18n from "../../../../../i18n";
import {
  fetchCheckUser,
  fetchExternal,
  fetchKycInit,
  fetchKycStatus,
  fetchKycUpdate,
  fetchMxUrl,
  fetchUserChangePassword,
  fetchUserLogout,
  fetchWallet,
  fetchWalletTransaction,
  fetchReferrals,
  loginUser,
} from "./userAction";
import { axiosSign } from "../../../paramters";

const initialState = {
  lang: sessionLangInit(),
  currentUrl: "/",
  currentSearch: null,
  isLogin: null,
  user: null,
  token: localStorage.getItem("token"),
  tokenType: localStorage.getItem("token_type"),
  loading: true,
  loadingToggleWaveRedirect: false,
  login: { error: null, pending: false },
  password: {
    pending: false,
    changed: false,
    error: false,
  },
  hasKyc: null,
  kycExpire: null,
  kycLoaded: false,
  kycRequirements: null,
  kycLevel: null,
  kycMustReload: false,
  kycStatus: {
    currentStatus: false,
    status: false,
    amountSum: 0,
    nbrTrs: 0,
  },
  newKyc: {
    newer: false,
    updater: false,
    pending: false,
    error: null,
    done: false,
    requirements: [],
    level: null,
  },
  mx: {
    loading: false,
    loaded: false,
    url: null,
  },
  wallets: {
    loading: false,
    loaded: false,
    data: [],
    meta: null,
  },
  wallet_transactions: {
    id: null,
    loading: false,
    loaded: false,
    data: [],
    meta: null,
    must_reload: 0,
  },
  externals: {
    loading: false,
    loaded: false,
    data: [],
    meta: null,
  },
  referrals: {
    currentPage: 1,
    list: [],
    loading: false,
    data: [],
    error: null,
    totalPages: 0,
    total: 0,
    perPage: 10,
  },
  registrationStep: {
    id: null,
    name: null,
    lname: null,
    mname: null,
    phone: null,
    occupation: null,
    dob_date: null,
    email: null,
    address: null,
    postalCode: null,
    city: null,
    province_id: null,
    genre: null,
    step: 1,
    country: null,
    step_date: {},
  },
};

// function isRejectedAction(action) {
//     return action.type.endsWith('rejected')
// }

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    /**
     * Update the current lang in store
     *
     * @param state
     * @param action
     */
    changeLang: (state, action) => {
      if (state.lang !== action.payload) {
        console.log(action, state.lang, "language change");
        state.lang = action.payload.lang;
        state.currentUrl = action.payload?.path;
        state.currentSearch = action.payload?.search;
        i18n.changeLanguage(state.lang);
        sessionStorage.setItem("langKey", state.lang);
        // window.location.reload();
      }
    },

    /**
     * Set initial lang
     * @param state
     */
    setLang: (state) => {
      i18n.changeLanguage(state.lang);
    },

    /**
     * set user login state
     *
     * @param state
     * @param action
     */
    setLogin: (state, action) => {
      state.isLogin = action.payload;
    },
    setLogout: (state) => {
      state.isLogin = false;
      state.user = null;
      state.hasKyc = null;
      state.kycLoaded = false;
      localStorage.removeItem("token");
      axiosSign.updateToken(null);
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    /**
     * Reset the login error recorded early
     *
     * @param state
     */
    resetLoginError: (state) => {
      state.login.error = false;
    },

    /**
     * Reset the password reset record early
     * @param state
     */
    resetPasswordError: (state) => {
      state.password.error = false;
    },

    /**
     * Reset the password change state to initial
     *
     * @param state
     */
    resetPasswordChanged: (state) => {
      state.password.changed = false;
    },

    /**
     * Updating user data in store after register successfully
     *
     * @param state
     * @param action
     */
    registerLogin: (state, action) => {
      state.user = action.payload.user;
      state.isLogin = true;

      //get the token from payload
      state.token = action.payload.access_token;

      // save token in local storage
      localStorage.setItem("token", action.payload.access_token);

      // set token to custom axios
      axiosSign.updateToken(action.payload.access_token);

      // set as newer four USA user for popup kyc modal
      state.newKyc.newer = parseInt(action.payload.user.country) === 2;
    },

    /**
     * Set kyc loaded as done
     *
     * @param state
     */
    setLoadedKyc: (state) => {
      state.kycLoaded = true;
    },

    /**
     * Update newer states and update kyc USA
     *
     * @param state
     * @param action
     */
    setNewerUpdater: (state, action) => {
      state.newKyc.newer = action.payload;
      state.newKyc.updater = action.payload;
    },

    /**
     * Update kyc USA
     *
     * @param state
     * @param action
     */
    setUpdater: (state, action) => {
      state.newKyc.updater = action.payload;
    },

    /**
     * Set the user wallet transaction id
     *
     * @param state
     * @param action
     */
    setWalletTransactionsId: (state, action) => {
      state.wallet_transactions.id = action.payload;
    },

    /**
     * Define if transaction list must reload after perfoming some action
     *
     * @param state
     * @param action
     */
    setMustReload: (state, action) => {
      state.wallet_transactions.must_reload = action.payload;
    },
    /**
     * Set the registration step
     *
     * @param state
     * @param action
     */
    setRegistrationStep1: (state, action) => {
      console.log(action.payload);

      state.registrationStep = action.payload;
    },
  },
  extraReducers: (builder) => {
    /** ************************************************************
     * User Check auth data
     * ************************************************************ */

    builder.addCase(fetchCheckUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCheckUser.fulfilled, (state, action) => {
      state.isLogin = true;
      state.loading = false;
      state.user = action.payload;
    });
    builder.addCase(fetchCheckUser.rejected, (state) => {
      state.isLogin = false;
      state.loading = false;
      state.user = null;
      state.token = null;
      state.tokenType = null;
    });

    /** ************************************************************
     * User password change
     * ************************************************************ */

    builder.addCase(fetchUserChangePassword.pending, (state) => {
      state.password.pending = true;
    });
    builder.addCase(fetchUserChangePassword.fulfilled, (state) => {
      state.password.pending = false;
      state.password.changed = true;
    });
    builder.addCase(fetchUserChangePassword.rejected, (state) => {
      state.password.pending = false;
      state.password.error = true;
    });

    /** ************************************************************
     * User Login
     * ************************************************************ */

    builder.addCase(loginUser.pending, (state) => {
      state.login.pending = true;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.login.pending = false;
      state.user = action.payload.user;
      state.isLogin = true;
      state.token = action.payload.access_token;
      localStorage.setItem("token", action.payload.access_token);

      axiosSign.updateToken(action.payload.access_token);
      console.log("loginUser", action.payload.access_token, state);
    });
    builder.addCase(loginUser.rejected, (state) => {
      state.login.error = true;
      state.login.pending = false;
    });

    /** ************************************************************
     * User Logout
     * ************************************************************ */

    builder.addCase(fetchUserLogout.fulfilled, (state, action) => {
      state.isLogin = action.payload === 1;
      state.user = null;
      state.hasKyc = null;
      state.kycLoaded = false;
      localStorage.removeItem("token");
      axiosSign.updateToken(null);
      console.log(
        "LoutGout",
        action.payload === 1,
        action.payload,
        state.isLogin
      );
    });

    /** ************************************************************
     * User kyc check
     * ************************************************************ */
    builder.addCase(fetchKycStatus.fulfilled, (state, action) => {
      state.kycStatus.amountSum = action.payload.amountSum;
      state.kycStatus.nbrTrs = action.payload.nbrTrs;
      state.kycStatus.status = action.payload.status === 0 ? false : true;

      state.hasKyc = action.payload.status !== null;
      state.kycExpire =
        action.payload.status === null ? false : action.payload.expire;
      state.kycLoaded = true;
      state.newKyc.kycMustReload = false;

      if (state.user && state.user?.country === 2) {
        state.kycRequirements = action.payload.upgrade_requirements;
        state.kycLevel = action.payload.level;
      }
    });
    builder.addCase(fetchKycStatus.rejected, (state) => {
      state.hasKyc = null;
      state.kycLoaded = false;
      state.kycRequirements = null;
      state.kycLevel = null;
    });

    /** ************************************************************
     * User fortress kyc init
     * ************************************************************ */

    builder.addCase(fetchKycInit.pending, (state) => {
      state.newKyc.pending = true;
      state.newKyc.done = false;
      state.newKyc.error = null;
      state.newKyc.level = null;
    });
    builder.addCase(fetchKycInit.fulfilled, (state, action) => {
      // state.newKyc.newer = true;
      state.newKyc.pending = false;
      state.newKyc.done = true;
      state.newKyc.requirements = action.payload.upgradeRequirements;
      state.newKyc.level = action.payload.kycLevel;
      state.kycMustReload = true;
    });
    builder.addCase(fetchKycInit.rejected, (state, action) => {
      state.newKyc.pending = false;
      state.newKyc.done = false;
      state.newKyc.error = action.payload;
      state.newKyc.level = null;
    });

    /** ************************************************************
     * User fortress kyc update
     * ************************************************************ */

    builder.addCase(fetchKycUpdate.pending, (state) => {
      state.newKyc.pending = true;
      state.newKyc.done = false;
      state.newKyc.error = null;
      state.newKyc.requirements = [];
      state.newKyc.level = null;
    });
    builder.addCase(fetchKycUpdate.fulfilled, (state, action) => {
      // state.newKyc.newer = false;
      state.newKyc.pending = false;
      state.newKyc.done = true;
      state.newKyc.requirements = action.payload.upgradeRequirements;
      state.newKyc.level = action.payload.kycLevel;
      state.kycMustReload = true;
    });
    builder.addCase(fetchKycUpdate.rejected, (state, action) => {
      state.newKyc.pending = false;
      state.newKyc.done = false;
      state.newKyc.error = action.payload;
      state.newKyc.requirements = [];
      state.newKyc.level = null;
    });

    /** ************************************************************
     * MX fortress
     * ************************************************************ */

    builder.addCase(fetchMxUrl.pending, (state) => {
      state.mx.loading = true;
      state.mx.url = null;
      state.mx.loaded = false;
    });
    builder.addCase(fetchMxUrl.fulfilled, (state, action) => {
      state.mx.loading = false;
      state.mx.url = action.payload;
      state.mx.loaded = true;
    });
    builder.addCase(fetchMxUrl.rejected, (state, action) => {
      state.mx.loading = false;
      state.mx.url = null;
      state.mx.loaded = false;
      state.mx.error = action.payload;
    });

    /** ************************************************************
     * User fortress wallet
     * ************************************************************ */

    builder.addCase(fetchWallet.pending, (state) => {
      state.wallets.loading = true;
      state.wallets.url = null;
      state.wallets.loaded = false;
    });
    builder.addCase(fetchWallet.fulfilled, (state, action) => {
      state.wallets.loading = false;
      state.wallets.data = action.payload.data;
      state.wallets.meta = action.payload.meta;
      state.wallets.loaded = true;
    });
    builder.addCase(fetchWallet.rejected, (state, action) => {
      state.wallets.loading = false;
      state.wallets.data = [];
      state.wallets.meta = null;
      state.wallets.loaded = false;
      state.wallets.error = action.payload;
    });

    /** ************************************************************
     * User fortress external account
     * ************************************************************ */

    builder.addCase(fetchExternal.pending, (state) => {
      state.externals.loading = true;
      state.externals.url = null;
      state.externals.loaded = false;
    });
    builder.addCase(fetchExternal.fulfilled, (state, action) => {
      state.externals.loading = false;
      state.externals.data = action.payload.data;
      state.externals.meta = action.payload.meta;
      state.externals.loaded = true;
    });
    builder.addCase(fetchExternal.rejected, (state, action) => {
      state.externals.loading = false;
      state.externals.data = [];
      state.externals.meta = null;
      state.externals.loaded = false;
      state.externals.error = action.payload;
    });

    /** ************************************************************
     * User fortress wallet transaction
     * ************************************************************ */

    builder.addCase(fetchWalletTransaction.pending, (state) => {
      state.wallet_transactions.loading = true;
      state.wallet_transactions.url = null;
      state.wallet_transactions.loaded = false;
    });
    builder.addCase(fetchWalletTransaction.fulfilled, (state, action) => {
      state.wallet_transactions.loading = false;
      state.wallet_transactions.data = action.payload.data;
      state.wallet_transactions.meta = action.payload.meta;
      state.wallet_transactions.loaded = true;
    });
    builder.addCase(fetchWalletTransaction.rejected, (state, action) => {
      state.wallet_transactions.loading = false;
      state.wallet_transactions.data = [];
      state.wallet_transactions.meta = null;
      state.wallet_transactions.loaded = false;
      state.wallet_transactions.error = action.payload;
    });

    /** ************************************************************
     * User Referrals
     * ************************************************************ */

    builder.addCase(fetchReferrals.pending, (state) => {
      state.referrals.loading = true;
    });
    builder.addCase(fetchReferrals.fulfilled, (state, action) => {
      state.referrals.loading = false;
      state.referrals.data = action.payload; // Stockez les données des parrainages
      state.referrals.totalPages = action.payload.totalPages;
      //state.referrals.currentPage = action.payload.currentPage;
    });
    builder.addCase(fetchReferrals.rejected, (state) => {
      state.referrals.loading = false;
      state.referrals.error = "Erreur lors de la récupération des parrainages";
    });
    // builder.addMatcher(isRejectedAction, (state, action) => {
    //     console.log(action.error.message);
    //     state.error = action.error.message;
    // });
  },
});

export const {
  setLang,
  setLogin,
  changeLang,
  registerLogin,
  resetLoginError,
  resetPasswordError,
  resetPasswordChanged,
  setLoadedKyc,
  setNewerUpdater,
  setUpdater,
  setLoading,
  setWalletTransactionsId,
  setMustReload,
  setRegistrationStep1,
  setLogout,
} = userSlice.actions;

export default userSlice.reducer;
