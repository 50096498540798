import "./App.scss";
import React from "react";
import { useSelector } from "react-redux";
// import AppRouter from "./app_routes";
import Loader from "./components/loader/Loader";
import { lazy, Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SupportContact from "./components/SupportContact";
import CookiesComponent from "./components/cookies/CookiesComponent";
import { HelmetProvider } from "react-helmet-async";

const AppRouter = lazy(() => import("./app_routes"));

/**
 * Main component
 *
 * This component manage the spinner loader of the application
 *
 * @returns {JSX.Element}
 */
function App() {
  /** Hooks instances **/
  const { t } = useTranslation();

  /** store selector **/
  // const isLoading = useSelector((state) => state.user.loading);
  const lang = useSelector((state) => state.user.lang);
  const [cookies, setCookies] = useState("");
  const [cookiesChange, setCookiesChange] = useState("");

  /**
   * Handle lang changes
   */
  useEffect(() => {
    // html lang
    document.documentElement.lang = lang;

    // keywords
    document.getElementById("meta_keywords").content = t("meta.keywords");
    setCookies(localStorage.getItem("cookies5"));
    if (cookiesChange != "") {
      setCookies("cookies5");
    }
  }, [lang, cookiesChange]);

  return (
    <>
      <HelmetProvider>
        {/* {isLoading && <Loader />}
        {!isLoading && (
          */}
        <>
          <Suspense fallback={<Loader />}>
            <AppRouter />

            {/* <AppRouter /> */}
            <SupportContact />
            {(cookies === "" || !cookies) && (
              <CookiesComponent setCookiesChange={setCookiesChange} />
            )}
          </Suspense>
        </>
        {/* )} */}
      </HelmetProvider>
    </>
  );
}

export default App;
