import { fetchCheckUser } from "../user/userAction";
import {
  fetchConstants,
  fetchJobOffer,
  fetchPageTransactions,
  fetchUserRecipient,
  getOnlinePayementStatus,
  pushNewTransaction,
  saveContact,
} from "./navigationAction";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  from: null,
  error: null,
  profile: {
    // user transaction state
    transaction: {
      list: [], // current list displayed
      allItem: [], // full list fetched
      allItemForDisplay: [],
      currentPage: 1,
      perPage: 10,
      total: 0,
      totalPage: 0,
      minDate: null,
      maxDate: null,
      recepient: "",
      loading: false,
      loaded: false,
    },
    recipients: [],
  },
  isCrating: false,
  // constants data states
  constants: {
    /** @type {{transferCountries:[...], transferType: [...]}} */
    data: [],
    loading: false,
    loaded: false,
    error: false,
  },
  // transfer states
  transfer: {
    country: sessionStorage.getItem("country"),
    idTransferType: sessionStorage.getItem("transferType"),
    AmountinCad: sessionStorage.getItem("cadAmount"),
    AmountinXaf: sessionStorage.getItem("destinationAmount"),
    idRate: sessionStorage.getItem("rate"),
    PromoCode: null,
    bonus: null,
    fname: null,
    lname: null,
    reasonOfSending: null,
    BankName: null,
    BranchName: null,
    town: null,
    Address: null,
    agence_code: null,
    AccountNumber: null,
    mobileNumber: null,
    for_other: false,
    third_party_address: null,
    third_party_relation: null,
    third_party_phone: null,
    third_party_dob: null,
    third_party_profesion: null,
    third_party_inc_number: null,
    cle: null,
    saving: false,
    error: null,
    saved: {
      amount: null,
      url: null,
      transaction_id: null,
      get_solde: undefined,
      paymentId: [],
    },
    wave_redirect: null,
  },
  // payement methode status
  onlinePayementStatus: {
    loading: false,
    error_message: null,
    data: {
      id: null,
      is_active: null,
      message_en: null,
      message_fr: null,
    },
  },
  // career states
  career: {
    categories: [],
    jobOffers: null,
    loading: false,
    loaded: false,
  },
  loadingContact: false,
};

// function isPendingAction(action) {
//     return action.type.endsWith('pending')
// }
/**
 * Slice extraReducer default onReject action
 * @param action
 * @returns {*}
 */
function isRejectedAction(action) {
  return action.type.endsWith("rejected");
}

/**
 * Navigation slice reducer relative to all data used by the user when navigating
 */
export const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    setComeFrom: (state, action) => {
      state.from = action.payload;
    },
    resetComeFrom: (state) => {
      state.from = null;
    },
    resetTransactionsLoaderState: (state) => {
      state.profile.transaction.loaded = false;
    },
    resetError: (state) => {
      state.error = null;
    },
    paginate: (state, action) => {
      state.profile.transaction.currentPage = action.payload;
      state.profile.transaction.list =
        state.profile.transaction.allItemForDisplay.slice(
          (state.profile.transaction.currentPage - 1) *
            state.profile.transaction.perPage,
          state.profile.transaction.currentPage *
            state.profile.transaction.perPage
        );
    },
    filterTransactionByDestinataires: (state, action) => {
      state.profile.transaction.selectedDestinataire =
        action.payload.selectedValue;

      state.profile.transaction.allItemForDisplay =
        action.payload.selectedValue === "all"
          ? state.profile.transaction.allItem
          : state.profile.transaction.allItem.filter(
              (element) =>
                element.fname + " " + element.lname ===
                state.profile.transaction.selectedDestinataire
            );

      state.profile.transaction.list =
        state.profile.transaction.allItemForDisplay.slice(
          0,
          state.profile.transaction.perPage
        );
      // state.profile.transaction.currentPage = action.payload.transactions.current_page;
      state.profile.transaction.total =
        state.profile.transaction.allItemForDisplay.length;
      const division =
        state.profile.transaction.total / state.profile.transaction.perPage;
      state.profile.transaction.totalPage = Number.isInteger(division)
        ? division
        : Math.floor(division) + 1;
    },
    filterTransaction: (state, action) => {
      state.profile.transaction.minDate = action.payload.min;
      state.profile.transaction.maxDate = action.payload.max;
      state.profile.transaction.recepient = action.payload.recepient;

      state.profile.transaction.allItemForDisplay =
        state.profile.transaction.allItem.filter((element) => {
          const date = element.created_at.split(" ")[0];
          if (
            state.profile.transaction.minDate &&
            state.profile.transaction.maxDate
          ) {
            if (
              date >= state.profile.transaction.minDate &&
              date <= state.profile.transaction.maxDate
            ) {
              return element;
            }
          } else if (
            state.profile.transaction.minDate &&
            !state.profile.transaction.maxDate
          ) {
            if (date >= state.profile.transaction.minDate) {
              return element;
            }
          } else if (
            !state.profile.transaction.minDate &&
            state.profile.transaction.maxDate
          ) {
            if (date <= state.profile.transaction.maxDate) {
              return element;
            }
          }
        });
      if (
        state.profile.transaction.minDate ||
        state.profile.transaction.maxDate
      ) {
        state.profile.transaction.allItemForDisplay =
          action.payload.recepient === "all" || action.payload.recepient === ""
            ? state.profile.transaction.allItemForDisplay
            : state.profile.transaction.allItemForDisplay.filter(
                (element) =>
                  element.fname + " " + element.lname ===
                  state.profile.transaction.recepient
              );
      } else {
        state.profile.transaction.allItemForDisplay =
          action.payload.recepient === "all" || action.payload.recepient === ""
            ? state.profile.transaction.allItem
            : state.profile.transaction.allItem.filter(
                (element) =>
                  element.fname + " " + element.lname ===
                  state.profile.transaction.recepient
              );
      }

      state.profile.transaction.list =
        state.profile.transaction.allItemForDisplay.slice(
          0,
          state.profile.transaction.perPage
        );
      // state.profile.transaction.currentPage = action.payload.transactions.current_page;
      state.profile.transaction.total =
        state.profile.transaction.allItemForDisplay.length;
      const division =
        state.profile.transaction.total / state.profile.transaction.perPage;
      state.profile.transaction.totalPage = Number.isInteger(division)
        ? division
        : Math.floor(division) + 1;
    },
    setLoadedTransaction: (state) => {
      state.profile.transaction.loaded = true;
    },

    /**
     * update state for new transfer in store
     */
    initTransfer: (state, action) => {
      state.transfer.country = action.payload.country;
      state.transfer.idTransferType = action.payload.transferType;
      state.transfer.AmountinCad = action.payload.cadAmount;
      state.transfer.AmountinXaf = action.payload.destinationAmount;
      state.transfer.idRate = action.payload.idRate;
    },

    /**
     * reset current transfer state
     *
     * @param state
     */
    resetTransfer: (state) => {
      state.transfer.country = null;
      state.transfer.idTransferType = null;
      state.transfer.AmountinCad = null;
      state.transfer.AmountinXaf = null;
      state.transfer.PromoCode = null;
      state.transfer.bonus = null;
      state.transfer.idRate = null;
      state.transfer.fname = null;
      state.transfer.lname = null;
      state.transfer.reasonOfSending = null;
      state.transfer.BankName = null;
      state.transfer.BranchName = null;
      state.transfer.town = null;
      state.transfer.Address = null;
      state.transfer.agence_code = null;
      state.transfer.AccountNumber = null;
      state.transfer.mobileNumber = null;
      state.transfer.cle = null;
      state.transfer.for_other = false;
      state.transfer.third_party_address = null;
      state.transfer.third_party_relation = null;
      state.transfer.third_party_phone = null;
      state.transfer.third_party_dob = null;
      state.transfer.third_party_profesion = null;
      state.transfer.third_party_inc_number = null;
    },

    /**
     * update transfer data with data from recipient
     *
     * @param state
     * @param action
     */
    preConfirmTransfer: (state, action) => {
      state.transfer.PromoCode = action.payload.PromoCode;
      state.transfer.bonus = action.payload.bonus;
      state.transfer.fname = action.payload.fname;
      state.transfer.lname = action.payload.lname;
      state.transfer.reasonOfSending = action.payload.reasonOfSending;
      state.transfer.BankName = action.payload.bankName;
      state.transfer.BranchName = action.payload.branchName;
      state.transfer.town = action.payload.town;
      state.transfer.Address = action.payload.address;
      state.transfer.agence_code = action.payload.agencyCode;
      state.transfer.AccountNumber = action.payload.accountNumber;
      state.transfer.cle = action.payload.key;
      state.transfer.mobileNumber = action.payload.mobileNumber;
      state.transfer.for_other = action.payload.for_other;
      state.transfer.third_party_address = action.payload.thirdPartyAddress;
      state.transfer.third_party_relation = action.payload.thirdPartyRelation;
      state.transfer.third_party_phone = action.payload.thirdPartyPhone;
      state.transfer.third_party_dob = action.payload.thirdPartyDob;
      state.transfer.third_party_profesion =
        action.payload.thirdPartyProfesionOrNature;
      state.transfer.third_party_inc_number = action.payload.thirdPartyNumber;
      state.transfer.wave_redirect = action.payload.wave_redirect;
    },

    /**
     * update transfer data by reseting the saved transfer
     *
     * @param state
     */
    resetConfirmTransfer: (state) => {
      state.transfer.saved.amount = null;
      state.transfer.saved.url = null;
      state.transfer.saved.transaction_id = null;
      state.transfer.saved.get_solde = undefined;
      state.transfer.saved.paymentId = [];
    },
    /**
     * mark transfer as not done
     *
     * @param state
     */
    resetDone: (state) => {
      state.transfer.done = false;
    },

    /**
     * reset all data of current transfer
     *
     * @param state
     */
    resetTransaction: (state) => {
      state.profile.transaction.list = [];
      state.profile.transaction.allItem = [];
      state.profile.transaction.allItemForDisplay = [];
      state.profile.transaction.currentPage = 1;
      state.profile.transaction.perPage = 10;
      state.profile.transaction.total = 0;
      state.profile.transaction.totalPage = 0;
      state.profile.transaction.minDate = null;
      state.profile.transaction.maxDate = null;
      state.profile.transaction.loading = false;
      state.profile.transaction.loaded = false;
    },

    /**
     * update current transfer state
     *
     * @param state
     * @param action
     */

    upDateSaved: (state, action) => {
      state.transfer.saved.amount = action.payload.amount;
      state.transfer.saved.transaction_id = action.payload.transaction_id;
      if (typeof action.payload.get_solde === "undefined") {
        state.transfer.saved.url = action.payload.url;
        // state.transfer.saved.paymentId = action.payload.paymentId[0].FT_TransId;
        state.transfer.saved.paymentId = action.payload.paymentId;
      } else {
        state.transfer.saved.get_solde = action.payload.get_solde;
      }
    },

    /**
     *
     * Change current waveRedirect state.
     *
     * @param state
     */
    toogleWaveRedirect: (state) => {
      state.transfer.wave_redirect = state.transfer.wave_redirect === 1 ? 0 : 1;
    },
  },
  extraReducers: (builder) => {
    /** ************************************************************
     * User Check loading
     * ************************************************************ */

    builder.addCase(fetchCheckUser.pending, (state) => {
      state.profile.transaction.loading = true;
    });
    builder.addCase(fetchCheckUser.fulfilled, (state) => {
      state.profile.transaction.loading = false;
    });
    builder.addCase(fetchCheckUser.rejected, (state) => {
      state.profile.transaction.loading = false;
    });

    /** ************************************************************
     * Transaction page
     * ************************************************************ */

    builder.addCase(fetchPageTransactions.pending, (state) => {
      state.profile.transaction.loading = true;
    });

    builder.addCase(fetchPageTransactions.fulfilled, (state, action) => {
      state.profile.transaction.loading = false;
      state.profile.transaction.allItem = action.payload.transactions;
      state.profile.transaction.allItemForDisplay =
        state.profile.transaction.allItem;
      state.profile.transaction.list =
        state.profile.transaction.allItemForDisplay.slice(
          0,
          state.profile.transaction.perPage
        );
      // state.profile.transaction.currentPage = action.payload.transactions.current_page;
      state.profile.transaction.total = action.payload.transactions.length;

      const division =
        state.profile.transaction.total / state.profile.transaction.perPage;
      state.profile.transaction.totalPage = Number.isInteger(division)
        ? division
        : Math.floor(division) + 1;
      state.profile.transaction.loaded = true;
    });

    builder.addCase(fetchPageTransactions.rejected, (state) => {
      state.profile.transaction.loading = false;
      state.profile.transaction.loaded = false;
    });

    /** ************************************************************
     * Constants
     * ************************************************************ */

    builder.addCase(fetchConstants.pending, (state) => {
      state.constants.loading = true;
      state.constants.data = [];
    });

    builder.addCase(fetchConstants.fulfilled, (state, action) => {
      state.constants.loading = false;
      state.constants.data = action.payload;
      localStorage.setItem("constants", JSON.stringify(action.payload));
      state.constants.loaded = true;
    });

    builder.addCase(fetchConstants.rejected, (state) => {
      state.constants.loading = false;
      state.constants.loaded = false;
    });
    builder.addCase(fetchUserRecipient.fulfilled, (state, action) => {
      state.profile.recipients = action.payload.recipients;
    });

    builder.addCase(pushNewTransaction.pending, (state) => {
      state.transfer.saving = true;
    });
    builder.addCase(pushNewTransaction.fulfilled, (state, action) => {
      state.transfer.saving = false;
      state.transfer.error = null;
      state.transfer.saved.amount = action.payload.amount;
      state.transfer.saved.transaction_id = action.payload.transaction_id;

      if (typeof action.payload.get_solde === "undefined") {
        state.transfer.saved.url = action.payload.url;
        state.transfer.saved.paymentId = action.payload.paymentId[0].FT_TransId;
      } else {
        state.transfer.saved.get_solde = action.payload.get_solde;
      }
    });
    builder.addCase(pushNewTransaction.rejected, (state, action) => {
      state.transfer.saving = false;
      state.transfer.error = action.payload;
    });

    /** ************************************************************
     * Job Offer
     * ************************************************************ */

    builder.addCase(fetchJobOffer.pending, (state) => {
      state.career.loading = true;
    });

    builder.addCase(fetchJobOffer.fulfilled, (state, action) => {
      state.career.loading = false;
      state.career.loaded = true;
      state.career.categories = action.payload.categories;
      state.career.jobOffers = action.payload.job_offer;
    });
    builder.addCase(fetchJobOffer.rejected, (state) => {
      state.career.loading = false;
      state.career.loaded = true;
    });
    /** ************************************************************
     * Contact us
     * ************************************************************ */
    builder.addCase(saveContact.pending, (state) => {
      state.loadingContact = true;
    });
    builder.addCase(saveContact.fulfilled, (state, action) => {
      state.loadingContact = false;
      state.contact = action.payload.contact;
    });
    builder.addCase(saveContact.rejected, (state) => {
      state.loadingContact = false;
    });

    builder.addCase(getOnlinePayementStatus.pending, (state) => {
      state.onlinePayementStatus.loadingContact = true;
      state.onlinePayementStatus.error_message = null;
    });
    builder.addCase(getOnlinePayementStatus.fulfilled, (state, action) => {
      state.onlinePayementStatus.loading = false;
      state.onlinePayementStatus.data = action.payload;
    });
    builder.addCase(getOnlinePayementStatus.rejected, (state, action) => {
      state.onlinePayementStatus.loadingContact = true;
      state.onlinePayementStatus.error_message = action.error.message;
    });

    /** ************************************************************
     * Default actions
     * ************************************************************ */

    builder.addMatcher(isRejectedAction, (state, action) => {
      // console.log(action.error.message);
      state.error = action.error.message;
    });
    // builder.addMatcher(isRejectedAction, (state, action) => {
    //     // console.log(action.error.message);
    //     state.error = action.error.message;
    // });
  },
});

export const {
  setComeFrom,
  resetComeFrom,
  resetTransactionsLoaderState,
  resetError,
  paginate,
  filterTransaction,
  initTransfer,
  resetTransfer,
  preConfirmTransfer,
  resetConfirmTransfer,
  resetDone,
  resetTransaction,
  setLoadedTransaction,
  filterTransactionByDestinataires,
  toogleWaveRedirect,
  upDateSaved,
} = navigationSlice.actions;
export default navigationSlice.reducer;
