/**
 * footer FR translations
 */
const footer = {
  main: "Transfergratis est un produit de onetransfer money transfer systems inc. la société est enregistrée au Canada auprès du CANAFE et de l’AMF en tant qu’entreprise de services monétaires.",
  copyrith: "Copyright © 2024 Transfergratis. Tous droits réservés.",
  aboutus: "A propos de nous",
  news: "Blog",
  careers: "Carrière",
  howwork: "Comment nous travaillons",
  Company: "Entreprise",
  Support: "Support",
  More: "Plus",
  FAQ: "FAQ",
  caOffice: "CA Office",
  afOffice: "Africa Office",
  term: "Terms and Conditions",
  policy: "politique de confidentialité",
  cookies: "Mentions légales",
  sendMoney: "Envoyer des fonds",
  sendIn: "Envoyer des fonds {{auOrEn}} {{country}}",
  issueAllet: "Signaler un problème",
};

export default footer;
