const bug = {
  title: "Signaler un bug",
  description:
    "Si vous rencontrez un bug, merci de nous le signaler. Nous ferons de notre mieux pour le corriger.",

  action: {
    send: "Envoyer",
    close: "Fermer",
    loading: "Envoi en cours...",
  },
  error: {
    title: "Une erreur est survenue lors de l'envoi du message.",
    description:
      "Un problème est survenu lors de l'envoi de votre message. Veuillez réessayer.",
  },
  success: {
    title: "Alerte sauvegardée",
    description: "Votre alerte a été enregistrée avec succès.",
  },
};

export default bug;
