// import "./loader.scss";
import Icon from "./Icone";
import React from "react";
import "./style.css";

/**
 * The main loader spinner in fullscreen
 * @returns {JSX.Element}
 */
export default function Loader() {
  return (
    <div className="loaderContainer">
      <div className="tgLoader">
        <Icon />
      </div>
    </div>
  );
}
