import { createAsyncThunk } from "@reduxjs/toolkit";
// import axios from "axios";
import { axiosSign } from "../../../paramters";

/**
 * POST - ExtraReducer call api perform login using credentials
 */
export const loginUser = createAsyncThunk(
  "user/loginUser",
  async (credentials) => {
    // return axios.get('/sanctum/csrf-cookie').then(async () => {
    return axiosSign
      .post({
        route: "/api/login",
        data: {
          ...credentials,
        },
      })
      .then((res) => {
        return res.data;
      });
    // })
  }
);

/**
 * POST - ExtraReducer call api fetching authenticated user data
 */
export const fetchCheckUser = createAsyncThunk(
  "user/fetchCheckUser",
  async () => {
    return axiosSign.post({ route: "/api/me" }).then((res) => {
      return res.data;
    });
  }
);

/**
 * GET - ExtraReducer call api fetching sur referrals
 */
export const fetchReferrals = createAsyncThunk(
  "user/fetchReferrals",
  async () => {
    const response = await axiosSign.get({ route: "/api/referal" });
    return response.data;
  }
);

/**
 * GET - ExtraReducer call api performing authenticated user logout
 */
export const fetchUserLogout = createAsyncThunk(
  "user/fetchUserLogout",
  async () => {
    return axiosSign.get({ route: "/api/logout" }).then((res) => {
      return res.data !== "";
    });
  }
);

/**
 * POST - ExtraReducer call api performing authenticated user password change
 */
export const fetchUserChangePassword = createAsyncThunk(
  "user/fetchUserChangePassword",
  async (data) => {
    return axiosSign
      .post({ route: "/api/updateP", data })
      .then((res) => res.data);
  }
);

/**
 * POST - ExtraReducer call api fetching authenticated user kyc status
 */
export const fetchKycStatus = createAsyncThunk(
  "user/fetchKycStatus",
  async (data) => {
    return axiosSign
      .post({ route: "/api/v2/statuskyc", data })
      .then((res) => res.data);
  }
);

/**
 * GET - ExtraReducer call api redirecting authenticated user on persona kyc page
 */
export const fetchKycInit = createAsyncThunk(
  "user/fetchKycInit",
  async (data, { rejectWithValue }) => {
    return axiosSign
      .get({ route: "/api/fortress/kyc-create", data })
      .then((res) => res.data)
      .catch((err) => rejectWithValue(err.response.data));
  }
);

/**
 * GET - ExtraReducer call api performing authenticated user fortress kyc update to L1
 */
export const fetchKycUpdate = createAsyncThunk(
  "user/fetchKycUpdate",
  async (data, { rejectWithValue }) => {
    return axiosSign
      .get({ route: "/api/fortress/kyc-make-address", data })
      .then((res) => res.data)
      .catch((err) => rejectWithValue(err.response.data));
  }
);

/**
 * GET - ExtraReducer call api fetching MX widget token for bank link
 */
export const fetchMxUrl = createAsyncThunk(
  "user/fetchMxUrl",
  async (data, { rejectWithValue }) => {
    return axiosSign
      .get({ route: "/api/fortress/mxIntegrationWidget", data })
      .then((res) => res.data)
      .catch((err) => rejectWithValue(err.response.data));
  }
);

/**
 * GET - ExtraReducer call api fetching authenticated user wallet
 */
export const fetchWallet = createAsyncThunk(
  "user/fetchWallet",
  async (data, { rejectWithValue }) => {
    return axiosSign
      .get({ route: "/api/fortress/custodial-accounts", data })
      .then((res) => res.data)
      .catch((err) => rejectWithValue(err.response.data));
  }
);

/**
 * GET - ExtraReducer call api fetching authenticated user wallet transactions
 */
export const fetchWalletTransaction = createAsyncThunk(
  "user/fetchWalletTransaction",
  async ({ custodial_account, page = 1 }, { rejectWithValue }) => {
    return axiosSign
      .get({
        route: `/api/fortress/getCustodialTransactions/${custodial_account}/${page}`,
      })
      .then((res) => res.data)
      .catch((err) => rejectWithValue(err.response.data));
  }
);

/**
 * GET - ExtraReducer call api fetching authenticated user external account
 */
export const fetchExternal = createAsyncThunk(
  "user/fetchExternal",
  async (data, { rejectWithValue }) => {
    return axiosSign
      .get({ route: "/api/fortress/external-accounts", data })
      .then((res) => res.data)
      .catch((err) => rejectWithValue(err.response.data));
  }
);
