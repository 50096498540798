const link = {
  send: "send-money-{{country}}",
  contactUs: "contact-us",
  faqs: "faqs",
  aboutUs: "about-us",
  resetPassword: "resetPassword",
  resetPasswords: "resetPasswords",
  login: "login",
  register: "register",
  transferInit: "transfer/init",
  connectMX: "connectMX",
  conditionsTerms: "conditions-terms",
  policy: "policy",
  cookies: "cookies",
  career: "career",
  careerJobOffer: "jobOffer",
  share: "share",
  transfer: "transfer",
  bug: "report-a-bug",
  registerStep: {
    step0: "initialize",
    step1: "location-information",
    step2: "personal-information",
    step3: "security-information",
  },
};

export default link;
