import axios from "axios";
import i18n from "../../i18n";

export class AxiosUtility {
  constructor(baseURL, fallback, requestSign) {
    this.axiosInstance = axios.create({
      baseURL,
      headers: {
        "Content-Type": "application/json",
        signature: requestSign,

        lang: i18n.language,
      },
      withCredentials: false,
    });

    // Intercepteur pour gérer les erreurs globalement
    this.axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        fallback(error); // Utilise le fallback en cas d'erreur
        return Promise.reject(error);
      }
    );
  }

  /**
   * Méthode pour mettre à jour le token dans les headers de l'instance Axios.
   * @param {string} token - Le nouveau token à mettre dans les headers.
   */
  updateToken(token) {
    this.axiosInstance.defaults.headers.Authorization = `Bearer ${token}`;
  }

  /**
   * Méthode de requête générique.
   * @param {string} method - Méthode HTTP (GET, POST, PUT, DELETE)
   * @param {string} route - Chemin de la route
   * @param {Object} data - Données envoyées dans la requête (facultatif)
   * @param {Object} config - Configuration supplémentaire Axios (facultatif)
   */
  request({ method, route, data, config }) {
    return this.axiosInstance.request({
      url: route,
      method,
      data,
      ...config,
    });
  }

  // Méthode GET
  get({ route, config }) {
    return this.request({ method: "GET", route, config });
  }

  // Méthode POST
  post({ route, data, config }) {
    return this.request({ method: "POST", route, data, config });
  }

  // Méthode PUT
  put({ route, data, config }) {
    return this.request({ method: "PUT", route, data, config });
  }

  // Méthode DELETE
  delete({ route, config }) {
    return this.request({ method: "DELETE", route, config });
  }
}
