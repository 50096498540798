const title = {
  heroTitle:
    "Transfergratis, Le moyen sûr, facile et rapide de transférer de l'argent {{article}} {{country}}",
  intro: {
    download: "Téléchargez Transfergratis maintenant !",
  },

  service: {
    title: "Avantages de notre service",
    subTitle:
      "Des transferts rapides, sécurisés et simples pour votre tranquillité d’esprit",
    cards1: {
      title: "Facile à utiliser",
      description:
        "Transfergratis est conçu pour faciliter l’envoi d’argent en quelques clics. Où que vous soyez, depuis votre téléphone, votre tablette ou votre ordinateur.",
    },
    cards2: {
      title: "Paiements rapides",
      description:
        "L’ensemble du système Transfergratis existe et est optimisé pour vous permettre de transférer de l’argent très rapidement.",
    },
    cards3: {
      title: "Gratuit",
      description:
        "Le service Transfergratis est entièrement gratuit, rien de plus que le montant exact que vous souhaitez transférer ne sera facturé.",
    },
    cards4: {
      title: "Sécurisé",
      description:
        "Transfergratis est agréé en tant qu’entreprise de services monétaires, ce qui signifie que nous sommes autorisés par le gouvernement canadien.",
    },
  },

  usage: {
    title: "C'EST DE LA TARTE",
    subTitle:
      "Rejoignez les milliers de clients satisfaits qui nous font confiance pour des transferts rapides, gratuits, et fiables",
    cards1: {
      title: "Inscription",
      description:
        "Inscrivez-vous sur Transfergratis et commencez à envoyer des fonds en quelques minutes ! Créez votre compte maintenant pour commencer. Rapide, gratuit, sécurisé !",
    },
    cards2: {
      title: "Envoyer de l'argent",
      description:
        "Le destinataire reçoit des fonds avec Transfergratis gratuitement du Canada au Cameroun dans quelques minutes.",
    },
    cards3: {
      title: "Le destinataire reçoit des fonds",
      description:
        "Le destinataire reçoit des fonds avec Transfergratis gratuitement du Canada au Cameroun dans quelques minutes.",
    },
  },
  testimony: {
    title: "Ce que disent nos clients",
    subTitle:
      "Découvrez les témoignages de ceux qui ont choisi notre service pour des transferts d'argent rapides, sécurisés et sans tracas. Leur satisfaction est notre priorité !",
  },
  blog: {
    title: "Dernières nouvelles",
    subTitle:
      "Restez informé des dernières mises à jour, fonctionnalités, et annonces importantes concernant notre service de transfert d'argent.",
  },
};
export default title;
