/**
 * Footer EN translations
 */
const footer = {
  main: "Transfergratis is a product of onetransfer money transfer systems inc. the company is registered in Canada with FINTRAC and the AMF as a money services business.",
  copyrith: "Copyright © 2024 Transfergratis . All rights reserved.",
  aboutus: "About Us",
  news: "News",
  careers: "Careers",
  howwork: "How we work",
  Company: "Company",
  Support: "Support",
  More: "More",
  FAQ: "FAQ",
  caOffice: "CA Office",
  afOffice: "Africa Office",
  term: "Terms and Conditions",
  policy: "Privacy Policy",
  cookies: "Legal Notice",
  sendMoney: "Send Money",
  sendIn: "Send money to {{country}}",
  issueAllet: "Point out a problem",
};

export default footer;
