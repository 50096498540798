/**
 * Message EN translations
 */
const message = {
  presence: "^*The field {{label}} is required",
  invalidField: "^*The field {{label}} is invalid",
  stringOnly: "^*The field {{label}} can only contain letters",
  passwordRequirements: "^*The password must be at least 8 characters",
  reasonOfSending: "You must choose a transfer reason",
  emailDuplicate: "This email is already used",
  anyError: "An error occurred",
  fname: "You must enter the first name",
  lname: "You must enter the name",
  email: "You must enter the email address",
  phone: "You must enter the phone number",
  dob: "You must enter your dob",
  password: "You must enter the password",
  confirmPassword: "^*You must fill in the password confirmation",
  occupation: "You must fill in the occupation",
  howHear: "You must fill in How you heard about us",
  postalCode: "You must enter the postal code",
  gender: "You must fill in the gender",
  captchatResponse: "You must validate the captchat",
  province: "You must enter the province",
  town: "You must enter the city",
  address: "You must enter the address",
  accountNumber: "You must enter the account number",
  confirmAccountNumber:
    "You must fill in the confirmation of the account number",
  kyc_verifying: "Verification and validation of your personal information..",
  kyc_waiting: "Thank you for your patience..",
  kyc_info_to_update:
    "There are some information to update before start transfer money",
  completed_verify: "Your verification is completed.",
  postulated: "Your application has been sent",
  test_advice:
    "For this job offer, your need to complete the test by following the link below",
  for_one: {
    thirdPartyAddress: "You must enter the address of the third party",
    thirdPartyRelation:
      "You must fill in the relationship between the third party and you",
    thirdPartyProfesionOrNature:
      "You must fill in the profession or nature of the activity of the third party",
    thirdPartyPhone: "You must fill in the phone number of the third party",
    thirdPartyDob_thirdPartyNumber_none:
      "You must fill in either a Date of birth of the third party, or an Incorporation / registration number and the jurisdiction of incorporation / registration (if the third party is an entity)",
    thirdPartyDob_thirdPartyNumber_defined:
      "You do not have to fill in both a Date of birth of the third party and Incorporation / registration number and the jurisdiction of incorporation / registration (if the third party is an entity)",
  },
  for_other: {
    thirdPartyAddress: "You must enter the address of the third party",
    thirdPartyRelation:
      "You must fill in the relationship between the third party and you",
    thirdPartyProfesionOrNature:
      "You must fill in the profession or nature of the activity of the third party",
    thirdPartyPhone: "You must fill in the phone number of the third party",
    thirdPartyDob_thirdPartyNumber_none:
      "You must fill in either a Date of birth of the third party, or an Incorporation / registration number and the jurisdiction of incorporation / registration (if the third party is an entity)",
    thirdPartyDob_thirdPartyNumber_defined:
      "You do not have to fill in both a Date of birth of the third party and Incorporation / registration number and the jurisdiction of incorporation / registration (if the third party is an entity)",
  },
  transfer_notice: {
    title: "Important: Check the Account number!",
    description:
      "Make sure the recipient's Account number is correct. TransferGratis is not responsible for funds sent to the wrong number.",
  },
  announcement: {
    new_ci:
      "🇨🇮Transfergratis now offers free and daily rate transfers to Ivory Coast! Send money today 🇨🇮",
    new_ng:
      "Transfergratis introduces free transfers to Nigeria at unbeatable rates! Start sending money today!",
  },
  readMore: "Read more",
  readMoreTestimony: "Show more testimony",
  showLess: "Show Less",
};

export default message;
