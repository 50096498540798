import aboutUs from "./about_us";
import contactUs from "./contact_us";
import errorPage from "./errorPage";
import faqs from "./faqs";
import footer from "./footer";
import globalLang from "./global";
import home from "./home";
import kyc from "./kyc";
import message from "./message";
import meta from "./meta";
import transaction from "./transaction";
import transfer from "./transfer";
import mx from "./mx";
import sharePage from "./sharePage";
import resetPage from "./resetPage";
import referral from "./referral";
import pdf from "./pdf";
import link from "./link";
import register from "./registration";
import bug from "./bug";
import title from "./title";

/**
 * All En lang data
 */
const langEn = {
  en: {
    translation: {
      contact_us: contactUs,
      about_us: aboutUs,
      errorPage: errorPage,
      faqs: faqs,
      footer: footer,
      global: globalLang,
      home: home,
      sharePage: sharePage,
      resetPage: resetPage,
      transaction: transaction,
      transfer: transfer,
      kyc: kyc,
      meta: meta,
      message: message,
      mx: mx,
      referral: referral,
      pdf: pdf,
      link: link,
      bug: bug,
      title: title,
      profileModalSub:
        "However, you can contact us to submit your problem via the channels available by following the link",
      profileModalTle:
        "For security reasons, it is no longer possible for you to edit your profile after completing the KYC.",

      "The email has already been taken.": "Email already used.",
      "The password must be at least 8 characters.":
        "Minimum 8 characters required",
      "Signial error message":
        "The network is disrupted, please try again with another payment method!",
      "Withdrawal methods available": "Withdrawal methods available",
      // "InformationErrorMessage": "Make sure the information entered is valid.",
      InformationErrorMessage:
        "Dear customer, to secure your transfers, please validate the information in your profile. This ensures the safety and efficiency of your transactions.",

      sendMail: "Send password reset link",
      sendedMail: "Email sent.",
      errorMail: "An error occurred. Please try again later.",
      close: "Close",
      titleMail: "Reset password.",
      register: register,
      selectedItems: "{{length}} items selected",
    },
  },
};

export default langEn;
