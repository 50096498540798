import {
  resetConfirmTransfer,
  resetTransfer,
} from "@src/constants/config/store/features/navigation/navigationSlice";
import { setLogout } from "@src/constants/config/store/features/user/userSlice";
import store from "@src/constants/config/store/store";

export const fallback = (error) => {
  console.error("Une erreur est survenue :", error.status);
  if (error.status === 401) {
    store.dispatch(resetTransfer());
    store.dispatch(setLogout());
    store.dispatch(resetConfirmTransfer());
    // resetSessionTransfer();
  }
};
