const bug = {
  title: "Report a bug",
  description:
    "If you encounter a bug, please report it. We will do our best to fix it.",
  action: {
    send: "Send",
    close: "Close",
    loading: "Sending...",
  },
  error: {
    title: "An error occurred",
    description:
      "An error occurred while sending your report. Please try again.",
  },
  success: {
    title: "Report saved",
    description: "Your report has been successfully sent.",
  },
};

export default bug;
