const title = {
  heroTitle:
    "Transfergratis, The safe, easy and Fast way to Transfer money with No fees {{article}} {{country}}",
  intro: {
    download: "Download Transfergratis now !",
  },

  service: {
    title: "Benefits of our service",
    subTitle: "Fast, Secure, and Hassle-Free Transfers for Your Peace of Mind",
    cards1: {
      title: "Easy to use",
      description:
        "Transfergratis is designed to make it easy to send money with just a few clicks. Wherever you are, from your phone, tablet or computer.",
    },
    cards2: {
      title: "Fast payments",
      description:
        "The entire Transfergratis system exists and is optimized to allow you to transfer money very quickly.",
    },
    cards3: {
      title: "Free of charge",
      description:
        "The Transfergratis service is completely free, nothing more than the exact amount you want to transfer will be charged.",
    },
    cards4: {
      title: "100% secure",
      description:
        "Transfergratis is licensed as a Money Services Business, which means that we are authorized by the Canadian Government.",
    },
  },
  usage: {
    title: "EASY AS 1.2.3",
    subTitle:
      "Join the thousands of satisfied customers who trust us for fast, free, and reliable transfers.",
    cards1: {
      title: "Register",
      description:
        "Sign up with Transfergratis and start sending money online in minutes•! Create your account now to get started. Fast, Free, Secure!",
    },
    cards2: {
      title: "Send money",
      description:
        "The recipient receives funds with Transfergratis for FREE to your loved ones in Cameroon in minutes.",
    },
    cards3: {
      title: "The recipient receives funds",
      description:
        "The recipient receives funds with Transfergratis for FREE to your loved ones in Cameroon in minutes.",
    },
  },
  testimony: {
    title: "What our customers are saying",
    subTitle:
      "Discover the testimonials of those who have chosen our service for fast, secure, and hassle-free money transfers. Their satisfaction is our top priority!",
  },
  blog: {
    title: "Latest news",
    subTitle:
      "Stay informed about the latest updates, features, and important announcements regarding our money transfer service.",
  },
};
export default title;
